import CourseCard from "../../Components/CourseCard/CourseCard";
import classes from "./CourseListContainer.module.css";
import { useContext } from "react";
import { CourseContext } from "../../Context/CourseContext";
import Loader from "../../Components/Loader/Loader";

const CourseListContainer = () => {
  // Context
  const { iseCourses } = useContext(CourseContext);

  if (iseCourses.isLoading) {
    return <Loader />;
  }

  return (
    <section className={classes.container}>
      {(iseCourses?.data as any[])?.map((data: any, i: number) => {
        return (
          <div key={i}>
            <CourseCard
              title={data?.name}
              image={data?.cover_image}
              description={data?.description}
              ratings={4}
              price={
                data.cohorts?.length > 0
                  ? data?.cohorts[data?.cohorts?.length - 1]?.price
                  : 0
              }
              id={data?.id}
              saved={data?.saved_courses}
              route={`/courses/${data?.id}/course-detail`}
            />
          </div>
        );
      })}
    </section>
  );
};

export default CourseListContainer;
