import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../Components/Button/Button";
import ProgressBar from "../../Components/ProgressBar/ProgressBar";
import { AuthUserContext } from "../../Context/AuthUserContext";
import classes from "./ProfileProgressRightCta.module.css";

const ProfileProgressRightCta = () => {
  // Router
  const navigate = useNavigate();

  // Context
  const { user } = useContext(AuthUserContext);
  return (
    <div className={classes.container}>
      <h4>Level up your profile</h4>
      <p>
        Completing your profile enables you to earn and unlock the verified
        profile badge.
      </p>
      <ProgressBar
        percentage={user?.data?.profile_completion_percentage || 0}
        color="#fff"
      />
      <Button
        type="tertiary"
        onClick={() => {
          navigate("/profile/user");
        }}
      >
        Complete profile
      </Button>
    </div>
  );
};

export default ProfileProgressRightCta;
